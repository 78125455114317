const events = [
  {
    poster_url:
      "https://res.cloudinary.com/dldrjl92a/image/upload/v1726997814/TechFusion_2k24_dkuqfr.jpg",

    event_name: "TECHFUSION 2k24",

    description:
      "Coding events inspire innovation, connect tech enthusiasts, and drive skill growth through hackathons, conferences, and meetups .",
  }
];

export default events;