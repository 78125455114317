const clubServices = [
  {
    id:0,
    poster_url:"https://res.cloudinary.com/dptdrzycj/image/upload/v1704288563/ArtificialIntelligence.d8f7e2c6fdc6070c5c3f_bbthnr.jpg",
    images:[],
    event_name: "Artificial Intelligence",
    description:
      "AI Club: Explore, Learn, Innovate. Dive into the world of Artificial Intelligence with our club. Join us for workshops, projects, and discussions on AI technology. Discover the future today!",
    
    Winners: [
      {
        name: "Parth Kulkarni",
        
      }
      ,
      {
        name:"Himanshu Mude"
      }
    ],
    Presentors:
      [
        {
        name:"Abdul Rehman"
      }
    ]
  },
 {
   id:1,
    
    poster_url:"https://res.cloudinary.com/dptdrzycj/image/upload/v1704288609/BlockChain.3ef03a28d2002a4c34e6_bxmpi7.jpg",

    event_name: "BlockChain",
    images:[],
    description:
      "Blockchain Club: Unlocking the Future. Join our club to delve into the world of blockchain technology. Learn, innovate, and discuss the potential of blockchain. Explore the future of secure, decentralized systems with us!",
    
    Winners: [
      {
        name: "Parth Kulkarni",
        
      }
      ,
      {
        name:"Himanshu Mude"
      }
    ],
    Presentors:
      [
        {
        name:"Abdul Rehman"
      }
    ]
  },
  {
    id:2,
    poster_url:"https://res.cloudinary.com/dmeicehn2/image/upload/v1726404051/ai_and_prompt_uyy11p.jpg",
    images:[],
    event_name: "Introduction to prompt Engineering and AI tools",
    description:
      "Transforming Words into Actions: The Power of Prompt Engineering We, Students Association of Information Technology  here to dive you into the World of Prompt Engineering! ",
    
    Winners: [
      {
        name: "-",
        
      }
      ,
      {
        name:"-"
      }
    ],
    Presentors:
      [
        {
        name:"Prapti Gawande and Shashank Pujari."
      }
    ]
  }

];

export default clubServices;