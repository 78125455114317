const posts = [
  {
    id:1,
    imageSrc:
      "https://res.cloudinary.com/dptdrzycj/image/upload/v1704289667/Screenshot_2024-01-03_191715_rbgyu9.png",
    title: "What is Public key cryptography ? ",
    description: "Awesome day at the beach! 🏖️ #SunsetVibes",
    link: "https://www.instagram.com/p/C1l7h9GpOgv/?utm_source=ig_web_copy_link",
  },
  {
    id:2,
    imageSrc:
      "https://res.cloudinary.com/dpeygvugv/image/upload/v1726401990/WCE_SAIT____Hello_Everyone___%EF%B8%8F__Greetings_from_SAIT__%EF%B8%8F__Unlocking_the_Power_of_SEO_____Optimizing_web_content_strategically_to_boost_vis___Instagram_-_Google_Chrome_15-09-2024_16_44_02_ky4ipz.png",
    title: "Search Engine Optimization ",
    description: "Awesome day at the beach! 🏖️ #SunsetVibes",
    link: "https://www.instagram.com/p/C2HcsCEAaJ_/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  },
  {
    id:3,
    imageSrc:
      "https://res.cloudinary.com/dpeygvugv/image/upload/v1726402094/Cloudinary_Management_Console_-_Media_Library_-_Google_Chrome_15-09-2024_17_37_32_ukgfje.png",
    title: "Classic STL Containers ",
    description: "Awesome day at the beach! 🏖️ #SunsetVibes",
    link: "https://www.instagram.com/p/C4NwtOdJmDk/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  },
  {
    id:4,
    imageSrc: 
      "https://res.cloudinary.com/dpeygvugv/image/upload/v1726402241/Cloudinary_Management_Console_-_Media_Library_-_Google_Chrome_15-09-2024_17_39_22_uv46hx.png",
    title: "What are Webhooks ? ",
    description: "Awesome day at the beach! 🏖️ #SunsetVibes",
    link: "https://www.instagram.com/p/C6IJaWdyvc1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  },
  {
    id:5,
    imageSrc:
      "https://res.cloudinary.com/dpeygvugv/image/upload/v1726402401/Cloudinary_Management_Console_-_Media_Library_-_Google_Chrome_15-09-2024_17_41_57_r1gwfa.png",
    title: "Introduction to Web Frameworks and Libraries ",
    description: "Awesome day at the beach! 🏖️ #SunsetVibes",
    link: "https://www.instagram.com/p/C-Jvrd8yQLO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  },
];

export default posts;
